@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* define css config */

.poppins-thin {
    font-family: "Poppins", system-ui;
    font-weight: 100;
    font-style: normal;
}

.poppins-extralight {
    font-family: "Poppins", system-ui;
    font-weight: 200;
    font-style: normal;
}

.poppins-light {
    font-family: "Poppins", system-ui;
    font-weight: 300;
    font-style: normal;
}

.poppins-regular {
    font-family: "Poppins", system-ui;
    font-weight: 400;
    font-style: normal;
}

.poppins-medium {
    font-family: "Poppins", system-ui;
    font-weight: 500;
    font-style: normal;
}

.poppins-semibold {
    font-family: "Poppins", system-ui;
    font-weight: 600;
    font-style: normal;
}

.poppins-bold {
    font-family: "Poppins", system-ui;
    font-weight: 700;
    font-style: normal;
}

.poppins-extrabold {
    font-family: "Poppins", system-ui;
    font-weight: 800;
    font-style: normal;
}

.poppins-black {
    font-family: "Poppins", system-ui;
    font-weight: 900;
    font-style: normal;
}

.poppins-thin-italic {
    font-family: "Poppins", system-ui;
    font-weight: 100;
    font-style: italic;
}

.poppins-extralight-italic {
    font-family: "Poppins", system-ui;
    font-weight: 200;
    font-style: italic;
}

.poppins-light-italic {
    font-family: "Poppins", system-ui;
    font-weight: 300;
    font-style: italic;
}

.poppins-regular-italic {
    font-family: "Poppins", system-ui;
    font-weight: 400;
    font-style: italic;
}

.poppins-medium-italic {
    font-family: "Poppins", system-ui;
    font-weight: 500;
    font-style: italic;
}

.poppins-semibold-italic {
    font-family: "Poppins", system-ui;
    font-weight: 600;
    font-style: italic;
}

.poppins-bold-italic {
    font-family: "Poppins", system-ui;
    font-weight: 700;
    font-style: italic;
}

.poppins-extrabold-italic {
    font-family: "Poppins", system-ui;
    font-weight: 800;
    font-style: italic;
}

.poppins-black-italic {
    font-family: "Poppins", system-ui;
    font-weight: 900;
    font-style: italic;
}

:root {
    --white-primary: #fff;
    --white-secondary: #fff;
    --black-primary: #000;
    --black-secondary: #31363F;
    --red-pirmary: #F65A83;
    --red-secondary: #FF87B2;
    --warning-pirmary: #FFE898;
    --warning-secondary: #FFE898;
    --info-primary: #5AB2FF;
    --info-secondary: #A0DEFF;
    --theme-purple-primary: #562e37;
    --theme-approve-primary: #C5D759;
    --theme-reject-primary: #D76B59;
    --theme-purple-secondary: #6d3b4a;
    --theme-orange-primary: #d8ab5a;
    --theme-orange-secondary: #d6a643;
    --theme-orange-third: #FDF6EC;
    --sidebar-width: 250px;
    --sidebar-iconbar-width: 90px;
    --body-gray: rgb(253 244 234);
    --fort-family: "Poppins", sans-serif;
    --table-header: #F9FAFB;
    --table-border-color: #E5E7EB;
    --table-header-text-color: #6B7280;
    --table-header-text-font-size: 12px;
    --table-body-text-color: #6B7280;
    --table-body-text-font-size: 14px;
    --heading-color: #202224;
    --heading-font-size: 32px;
    --sub-heading-color: #636466;
    --sub-heading-font-size: 18px;
    --menu-text-color: #4B5563;
    --menu-text-font-size: 14px;
    --input-border-color: #D1D5DB;
    --form-label-color: #000000;
    --form-label-font-size: 14px;
    --form-label-input-gap: 8px;
    --form-between-input-bottom-gap: 32px;
    --button-padding-left: 25px;
    --button-padding-right: 25px;
    --accordion-title-font-size: 16px;
    --accordion-title-color: #111827;
    --color-muted-one: #a69196;

    --status-bg-new: #b5b5f3;
    --status-text-new: #0000ff;
    --status-bg-rejected: #FEE2E2;
    --status-text-rejected: #E83E3E;
    --status-bg-in-review: #fff9ed;
    --status-text-in-review: #d6a643;
    --status-bg-approved: #f9e9ed;
    --status-text-approved: #562e37;
    --status-bg-settled: #D1FAE5;
    --status-text-settled: #065F46;
}

.order-status-new-bg,
.order-item-status-new-bg {
    background-color: var(--status-bg-new);
    color: var(--status-text-new);
}

.order-status-in-review-bg,
.order-item-status-in-review-bg,
.order-status-inprogress-bg,
.order-status-disbursal-bg,
.order-item-status-admin-review-bg,
.order-item-status-super-admin-review-bg {
    background-color: var(--status-bg-in-review);
    color: var(--status-text-in-review);
}

.order-status-reject-bg,
.order-item-status-rejected-bg {
    background-color: var(--status-bg-rejected);
    color: var(--status-text-rejected);
}

.order-status-approved-bg,
.order-item-status-approved-bg {
    background-color: var(--status-bg-approved);
    color: var(--status-text-approved);
}

.order-status-settled-bg {
    background-color: var(--status-bg-settled);
    color: var(--status-text-settled);
}

nav#sidebar.mobile-menu {
    /* position: fixed; */
    top: 0;
    left: var(--sidebar-width);
    z-index: 99999;
    box-shadow: var(--bs-box-shadow) !important;
    transform: all 0.3s;
}

.mobile-header {
    background: var(--theme-orange-primary);
}

.svg-icon.svg-white {
    fill: var(--white-primary) !important;
}

body>* {
    font-family: "Poppins", system-ui;
    font-weight: 400 !important;
    font-style: normal;
}

.denied-box p {
    color: var(--menu-text-color)
}

.denied-box {
    padding: 30px 10px;
    border-radius: 15px;
    border: 1px solid var(--red-secondary);
}

.form-group label:first-child {
    color: var(--form-label-color);
    font-size: var(--form-label-font-size);
    font-weight: 600;
    margin-bottom: 10px !important;
}

.permission-denied {
    width: 360px;
    margin: 0 auto;
    height: calc(100vh - 65px);
}

.accordion-header button.accordion-button {
    font-size: var(--accordion-title-font-size);
    color: var(--accordion-title-color);
}

/* process not  */
body>iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
    display: none;
}


/* layout */
.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    height: 100vh;
    /* Ensure the container takes up the full viewport height */
    overflow: hidden;
}

#sidebar {
    min-height: 100vh;
    min-width: var(--sidebar-width);
    max-width: var(--sidebar-width);
    background-color: var(--theme-orange-primary) !important;
    box-shadow: 0 0 21px 0 #ffffff1a;
    transition: all 0.3s;
}

#sidebar.icon-sidebar {
    min-height: 100vh;
    min-width: var(--sidebar-iconbar-width);
    max-width: var(--sidebar-iconbar-width);
    background-color: var(--theme-orange-primary) !important;
    box-shadow: 0 0 21px 0 #ffffff1a;
    transition: all 0.3s;
    margin-left: 0px;
}

/* #sidebar {
    margin-left: calc(-1 * var(--sidebar-width));
} */

#content {
    width: 100%;
    overflow-y: auto;
    /* min-height: 90vh; */
    transition: all 0.3s;
}


/* ---------------------------------------------------
      MEDIAQUERIES
  ----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        margin-left: calc(-1 * var(--sidebar-width));
    }

    #sidebar.active {
        margin-left: 0;
    }
}


/*loader*/
.cs-loader {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
}

.cs-loader-inner {
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    width: calc(100% - 0px);
    color: var(--white-primary);
    text-align: center;
}

.sk-folding-cube {
    margin: 20px auto;
    width: 150px;
    height: 32px;
    position: relative;
}

.loader {
    display: block;
    position: relative;
    height: 32px;
    width: 150px;
    box-sizing: border-box;
    overflow: hidden;
    border: 2px solid var(--white-primary);
    border-radius: 20px;
}

.loader:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 2px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: var(--theme-orange-primary);
    animation: ballbns 3s ease-in-out infinite;
}

@keyframes ballbns {
    0% {
        left: 0;
        transform: translateX(0%);
        box-shadow: -5px 0 0 -1px rgba(209, 156, 61, 0.9),
            -10px 0 0 -2px rgba(209, 156, 61, 0.8),
            -15px 0 0 -4px rgba(209, 156, 61, 0.6),
            -20px 0 0 -6px rgba(209, 156, 61, 0.4),
            -25px 0 0 -8px rgba(209, 156, 61, 0.2);
    }

    49% {
        left: 100%;
        transform: translateX(-100%);
        box-shadow: -5px 0 0 -1px rgba(209, 156, 61, 0.9),
            -10px 0 0 -2px rgba(209, 156, 61, 0.8),
            -15px 0 0 -4px rgba(209, 156, 61, 0.6),
            -20px 0 0 -6px rgba(209, 156, 61, 0.4),
            -25px 0 0 -8px rgba(209, 156, 61, 0.2);
    }

    51% {
        left: 100%;
        transform: translateX(-100%);
        box-shadow: 5px 0 0 -1px rgba(209, 156, 61, 0.9),
            10px 0 0 -2px rgba(209, 156, 61, 0.8),
            15px 0 0 -4px rgba(209, 156, 61, 0.6),
            20px 0 0 -6px rgba(209, 156, 61, 0.4),
            25px 0 0 -8px rgba(209, 156, 61, 0.2);
    }

    100% {
        left: 0;
        transform: translateX(0%);
        box-shadow: 5px 0 0 -1px rgba(209, 156, 61, 0.9),
            10px 0 0 -2px rgba(209, 156, 61, 0.8),
            15px 0 0 -4px rgba(209, 156, 61, 0.6),
            20px 0 0 -6px rgba(209, 156, 61, 0.4),
            25px 0 0 -8px rgba(209, 156, 61, 0.2);
    }
}


/* custom */
.bg-custom-primary {
    /* background-color: var(--light-green-primary) !important; */
}

.bg-custom-secondary {
    /* background-color: var(--light-green-secondary) !important; */
}

.bg-custom-info {
    /* background-color: var(--info-primary) !important; */
}

.bg-custom-warning {
    /* background-color: var(--warning-secondary) !important; */
}

.bg-custom-danger {
    /* background-color: var(--red-secondary) !important; */
}

.bg-custom-white {
    background-color: var(--white-primary) !important;
}

.text-primary {
    color: var(--white-primary) !important;
}

.text-secondary {
    color: var(--white-secondary) !important;
}

.text-warning {
    color: var(--warning-pirmary) !important;
}

.text-danger {
    color: var(--red-secondary) !important;
}

.text-white {
    color: var(--white-primary) !important;
}

.text-black {
    color: var(--black-primary) !important;
}

.text-orange {
    color: var(--theme-orange-primary) !important;
}

.text-muted-one {
    color: var(--color-muted-one) !important;
}

.text-underline {
    text-decoration: underline;
}

#sidebar ul li div.active .svg-icon {
    fill: var(--white-primary) !important;
}

#sidebar ul li div.active {
    background-color: var(--theme-purple-primary);
    color: var(--white-primary);
}

#sidebar ul li div:hover {
    background-color: var(--theme-purple-secondary);
}

#sidebar ul li a,
#sidebar ul li a:hover {
    color: var(--white-primary);
    font-size: var(--menu-text-font-size);
    font-weight: 600;
}

/* #sidebar ul:last-child li a:hover {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: 2px solid var(--text-secondary-color);
    background-color: var(--bg-primary-color);
}

#sidebar ul:last-child li:not(.subli) a.active {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: 2px solid var(--text-secondary-color);
    background-color: var(--bg-primary-color);
} */

.is-invalid {
    border: 1px solid var(--red-secondary);
    border-radius: 4px;
}


.sidebar-profile {
    line-height: 40px;
    text-align: center;
}

img.profile-icon {
    width: 25px !important;
    border-radius: 50%;
}

.mr-15 {
    margin-right: 15px;
}

.submenu-icon {
    position: relative;
}

.submenu-icon .state-icon {
    position: absolute;
    right: 25px;
}


.svg-icon.text-white {
    fill: var(--white-primary) !important;
}

.svg-icon.text-black {
    fill: var(--black-secondary) !important;
}

.svg-icon {
    fill: var(--text-secondary-color) !important;
    vertical-align: middle;
    line-height: 0 !important;
    position: relative;
    /* top: -2px; */
}

.svg-icon.no-fill {
    fill: none !important;
}

.svg-icon.danger {
    fill: var(--red-secondary) !important;
}


p.page-not-found {
    margin: 0;
    height: 50vh;
    vertical-align: middle;
    line-height: 50vh;
    font-size: 50px;
    color: var(--white-secondary);
}

.permission-tr {
    border-top: 1px solid var(--bg-secondary-color);
    border-style: dotted;
}

.badge {
    font-size: 14px;
    font-weight: 400;
}

table .table-light {
    background-color: var(--table-header);
    border-color: var(--table-border-color);
}

table tbody td {
    background-color: var(--table-header);
    border-color: var(--table-border-color);
}

table.permission span.badge {
    margin-right: 3px;
}

table th {
    color: var(--table-header-text-color);
    font-size: var(--table-header-text-font-size);
}

table td {
    color: var(--table-body-text-color);
    font-size: var(--table-body-text-font-size);
}

/* 
table thead tr th {
    background-color: var(--bg-primary-color) !important;
    color: var(--text-secondary-color) !important;
}

table tbody tr td {
    background-color: var(--bg-secondary-color) !important;
    color: var(--text-secondary-color) !important;
}

.table {
    --bs-table-border-color: var(--bg-primary-color) !important;
} */

.login-box {
    margin: 0 auto;
    width: 360px;
    border-radius: 5px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--white-primary);
    display: inline-table;
    padding: 40px;
}


.login-box form {
    width: 360px;
    margin: 0 auto;
}


.error-message {
    color: var(--red-secondary);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: var(--theme-orange-primary);
    border-color: var(--theme-orange-primary);
    color: var(--white-primary);
}

.p-dropdown {
    margin-right: 15px;
}

.mr-2 {
    margin-right: 2px;
}


.p-multiselect-items,
.p-dropdown-items {
    padding-left: 0px;
}

.side-menu-list {
    overflow-y: auto;
    max-height: calc(100vh - 56px);
    background-color: transparent;
    border: none;
}

.side-menu-list ul {
    padding-left: 0px;
}

.side-menu-list ul li {
    padding: 5px 20px;
}

.side-menu-list ul li div.p-menuitem-content {
    border-radius: 5px;
}

.side-menu-list ul li a span:nth-child(2) {
    margin-left: 5px !important;
}

.side-menu-list ul li a svg.svg-icon,
.p-menuitem-content .svg-icon {
    top: 0px;
}

.side-menu-list ul li a span:nth-child(2),
.p-menuitem-content a span:nth-child(2) {
    margin-left: 5px !important;
}

.side-menu-list ul li a,
.p-menuitem-content a {
    text-decoration: none;
}

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 5px;
    /* Adjust width for horizontal scrollbar */
    height: 5px;
    /* Adjust height for vertical scrollbar */
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Track color */
}

::-webkit-scrollbar-thumb {
    background: #888;
    /* Scrollbar color */
    border-radius: 10px;
    /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Color on hover */
}

/* For Firefox */
/* scrollbar-width: thin; 
  scrollbar-color: #888 #f1f1f1;  */
.notification-badge {
    top: -6px;
    left: 12px;
    background: var(--red-pirmary);
    border-radius: 5px;
    padding: 0px 2px;
    color: var(--gray-primary);
    font-size: 10px;
}

.single-line th,
.single-line td {
    white-space: nowrap;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border: none;
}

/* .p-datatable .p-column-header-content {
    display: unset;
} */
.p-datatable .p-column-header-content {
    display: table;
    width: 100%;
}

/* .p-datatable .p-column-header-content span[data-pc-section="sort"] {
    float: right;
} */

/* cursor properties */
.cursor-alias {
    cursor: alias;
}

.cursor-all-scroll {
    cursor: all-scroll;
}

.cursor-auto {
    cursor: auto;
}

.cursor-cell {
    cursor: cell;
}

.cursor-col-resize {
    cursor: col-resize;
}

.cursor-context-menu {
    cursor: context-menu;
}

.cursor-copy {
    cursor: copy;
}

.cursor-crosshair {
    cursor: crosshair;
}

.cursor-default {
    cursor: default;
}

.cursor-e-resize {
    cursor: e-resize;
}

.cursor-ew-resize {
    cursor: ew-resize;
}

.cursor-grab {
    cursor: grab;
}

.cursor-grabbing {
    cursor: grabbing;
}

.cursor-help {
    cursor: help;
}

.cursor-move {
    cursor: move;
}

.cursor-n-resize {
    cursor: n-resize;
}

.cursor-ne-resize {
    cursor: ne-resize;
}

.cursor-nesw-resize {
    cursor: nesw-resize;
}

.cursor-ns-resize {
    cursor: ns-resize;
}

.cursor-nw-resize {
    cursor: nw-resize;
}

.cursor-nwse-resize {
    cursor: nwse-resize;
}

.cursor-no-drop {
    cursor: no-drop;
}

.cursor-none {
    cursor: none;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-progress {
    cursor: progress;
}

.cursor-row-resize {
    cursor: row-resize;
}

.cursor-s-resize {
    cursor: s-resize;
}

.cursor-se-resize {
    cursor: se-resize;
}

.cursor-sw-resize {
    cursor: sw-resize;
}

.cursor-text {
    cursor: text;
}

.cursor-w-resize {
    cursor: w-resize;
}

.cursor-wait {
    cursor: wait;
}

.cursor-zoom-in {
    cursor: zoom-in;
}

.cursor-zoom-out {
    cursor: zoom-out;
}

.p-contextmenu-root-list {
    padding: 0px;
    margin: 0px;
}

.p-dialog .p-dialog-header,
.p-dialog-footer {
    padding: 15px;
}

.p-dialog-content {
    padding: 0px 15px;
}

.svg-icon.svg-icon-model {
    top: 0px
}

.p-togglebutton .p-button {
    padding: 3px 15px;
}

.country-drop-down {
    border: none;
    margin: 0px;
}

.country-drop-down span.p-dropdown-label {
    padding: 3px;
    width: 100px;
}

/* spinner */


.spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    /* position: absolute;
    top: 50%;
    left: 50%; */
    margin-top: -5px;
    /* width: 10px;
    height: 10px; */

    & .path {
        stroke: var(--white-primary);
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

.p-multiselect .p-multiselect-label {
    padding: 0.20rem;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0px 10px;
}

/* .p-paginator-page.p-highlight {

    background: var(--light-green-primary) !important;
    color: var(--white-primary) !important;
} */

/* .custom-single-dropdown .p-dropdown-label.p-inputtext {
    padding: 0.20rem 0.45rem;
} */

.attachment-placeholder {
    position: relative;
    min-height: 75px;
    max-height: 75px;
    width: 100%;
    background: var(--light-green-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 15px;
}

img.image-preview {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

img.object-fit {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.img-preview-option {
    position: absolute;
    display: flex;
    flex-direction: row;
    bottom: 0;
    right: 2px;
}

.img-preview-option span {
    margin-left: 5px;
    cursor: pointer;
    background: #fff;
    border-radius: 5px;
    padding: 0 3px;
}

.file-preview-model .p-dialog-content img {
    object-fit: contain;
}

.file-preview-model .p-dialog-content {
    padding-bottom: 15px;
    height: 80vh;
}

.date-picker-input.p-calendar input,
.date-picker-input.p-calendar button {
    padding: 0.25rem 0.25rem;
}

.date-picker-input.p-calendar {
    width: 100%;
}

img.image-preview.pdf-view {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.btn-purple-primary:disabled {
    color: var(--white-primary);
    pointer-events: none;
    background-color: var(--theme-purple-primary);
    opacity: 0.9;
}

.btn-orange-primary:disabled {
    color: var(--white-primary);
    pointer-events: none;
    background-color: var(--theme-orange-primary);
    opacity: 0.9;
}

.btn-purple-primary,
.btn-purple-primary:active,
.btn-purple-primary.show,
.btn-purple-primary.show:active:focus-visible,
.btn-purple-primary:focus {
    border: 1px solid var(--theme-purple-primary);
    outline: none;
    background: var(--theme-purple-primary);
    color: var(--white-primary);
    padding-left: var(--button-padding-left);
    padding-right: var(--button-padding-right);
}

.btn-purple-primary:hover {
    border: 1px solid var(--theme-purple-primary);
    outline: none;
    background: var(--theme-purple-secondary);
    color: var(--white-primary);
    padding-left: var(--button-padding-left);
    padding-right: var(--button-padding-right);
}

.btn-purple-primary-outline,
.btn-purple-primary-outline:active,
.btn-purple-primary-outline:focus,
.btn-purple-primary-outline.show {
    border: 1px solid var(--theme-purple-primary);
    outline: none;
    background: transparent;
    color: var(--theme-purple-primary);
    padding-left: var(--button-padding-left);
    padding-right: var(--button-padding-right);
}

.btn-purple-primary-outline:hover {
    border: 1px solid var(--theme-purple-primary);
    outline: none;
    background: transparent;
    color: var(--theme-purple-primary);
    padding-left: var(--button-padding-left);
    padding-right: var(--button-padding-right);
}

.btn-primary {
    --bs-btn-active-bg: var(--theme-orange-primary);
    --bs-btn-active-color: var(--white-primary);

}

.btn-orange-primary-outline,
.btn-orange-primary-outline:active,
.btn-orange-primary-outline:focus,
.btn-orange-primary-outline.show {
    border: 1px solid var(--theme-orange-primary);
    outline: none;
    background: transparent;
    color: var(--theme-orange-primary);
    padding-left: var(--button-padding-left);
    padding-right: var(--button-padding-right);
}

.btn-orange-primary-outline:hover {
    border: 1px solid var(--theme-orange-primary);
    outline: none;
    background: transparent;
    color: var(--theme-orange-primary);
    padding-left: var(--button-padding-left);
    padding-right: var(--button-padding-right);
}

.btn-orange-primary,
.btn-orange-primary:active,
.btn-orange-primary.show:active:focus-visible,
.btn-orange-primary:focus,
.btn-orange-primary.show {
    border: 1px solid var(--theme-orange-primary);
    outline: none;
    background: var(--theme-orange-primary);
    color: var(--white-primary);
    padding-left: var(--button-padding-left);
    padding-right: var(--button-padding-right);
}

.btn-orange-primary:hover {
    border: 1px solid var(--theme-orange-primary);
    outline: none;
    background: var(--theme-orange-secondary);
    color: var(--white-primary);
    padding-left: var(--button-padding-left);
    padding-right: var(--button-padding-right);
}


.btn-approve-primary,
.btn-approve-primary:active,
.btn-approve-primary:hover,
.btn-approve-primary:focus {
    border: 1px solid var(--theme-approve-primary);
    outline: none;
    background: var(--theme-approve-primary);
    color: var(--white-primary);
    padding-left: var(--button-padding-left);
    padding-right: var(--button-padding-right);
}

.btn-reject-primary,
.btn-reject-primary:active,
.btn-reject-primary:hover,
.btn-reject-primary:focus {
    border: 1px solid var(--theme-reject-primary);
    outline: none;
    background: var(--theme-reject-primary);
    color: var(--white-primary);
    padding-left: var(--button-padding-left);
    padding-right: var(--button-padding-right);
}

.placeholder-image-bg img {
    object-fit: scale-down;
    height: 100%;
    width: 100%;
}

.placeholder-image-bg {
    width: 80px;
    height: 80px;
    background: rgb(220 230 236);
    border-radius: 50%;
    overflow: hidden;
}


.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload-btn {
    border: 1px solid var(--theme-orange-primary);
    color: var(--white-primary);
    background-color: var(--theme-orange-primary);
}

.upload-btn-bdr {
    border: 1px solid var(--theme-orange-primary);
    color: var(--theme-orange-primary);
    background-color: var(--white-primary);
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.custom-accordiant .accordion-item {
    border: none;
}

.custom-accordiant .accordion-item button.accordion-button {
    background: transparent;
}

.card {
    border: none;
}

.accordion-button:focus {
    box-shadow: none;
}

.user-profile button,
.user-profile button:hover,
.user-profile button:active,
.user-profile button:focus {
    background: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    padding-top: 0px !important;
}


.user-profile {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 208px;
}

.p-progressbar {
    height: 14px;
}

.img-layer {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    cursor: pointer;
}

.upload-thumb-icon {
    width: 36px;
    height: 36px;
}

.badge-active {
    background-color: #D1FAE5;
    color: #065F46;
    padding-bottom: 5px;
}

.badge-inactive {
    background-color: #FEE2E2;
    color: #E83E3E;
    padding-bottom: 5px;
}

button.form-status-btn-active {
    background-color: #D1FAE5 !important;
    border-radius: 20px;
    padding: 5px 20px;
    color: #065F46 !important;
    position: relative;
}

button.form-status-btn-active.active,
button.form-status-btn-inactive.active {
    border: 1px solid #636466;
}


button.form-status-btn-active.active:before {
    content: "";
    background: #34D399;
    width: 8px;
    height: 8px;
    display: inline-flex;
    border-radius: 50%;
    position: absolute;
    top: 14px;
}

button.form-status-btn-inactive {
    background-color: #FEE2E2 !important;
    border-radius: 20px;
    padding: 5px 20px;
    color: #E83E3E !important;
    position: relative;
}


button.form-status-btn-inactive.active:before {
    content: "";
    background: #e04848ab;
    width: 8px;
    height: 8px;
    display: inline-flex;
    border-radius: 50%;
    position: absolute;
    top: 14px;
}

.date-picker-input.p-calendar svg {
    color: #000;
}

input[readonly] {
    background-color: #e9e9e9;
    /* Light gray background */
    cursor: not-allowed;
    /* Change cursor to indicate it’s not editable */
}

.step-current {
    fill: #DEB468;
}

.step-completed {
    fill: #10B981;
}

.default-step {
    border: 2px solid #636466;
    border-radius: 50%;
    width: 40px;
    font-size: 16px;
    height: 40px;
    display: flow-root;
    text-align: center;
    vertical-align: middle;
    line-height: 38px;
}

.item-box {
    padding: 15px;
    border: 1px solid rgb(209 213 219/30%);
    border-radius: 10px;
    background-color: rgb(211 159 64 / 3%);
    ;
}

.cus-file-upload::file-selector-button {
    border: 1px solid var(--theme-orange-primary);
    outline: none;
    background: transparent;
    color: var(--theme-orange-primary);
    /* padding-left: 40px;
    padding-right: 40px; */
    border-radius: 5px;
}

/* 
.btn-orange-primary-outline,
.btn-orange-primary-outline:active,
.btn-orange-primary-outline:focus {

}

.btn-orange-primary-outline:hover {
    border: 1px solid var(--theme-orange-primary);
    outline: none;
    background: transparent;
    color: var(--theme-orange-primary);
    padding-left: 40px;
    padding-right: 40px;
} */

.svg-icon.delete-item-icon {
    fill: black !important;
}

input.p-autocomplete-input,
input.p-autocomplete-input:focus,
input.p-autocomplete-input:active,
input.p-autocomplete-input:hover {
    padding: 0;
    margin: 0;
    border: none;
    width: 100%;
    outline: 0px;
    box-shadow: none;
}

.p-autocomplete-items {
    padding: 10px 0px;
    margin: 0px;
}

.gray-color {
    color: gray;
}

.trade-customer-section .form-group {
    margin-top: 30px;
}

.remove-dd.input-group .p-dropdown-trigger {
    display: none;
}

#item-details-tab {
    border: none;
}

#item-details-tab button {
    border: 1px solid #636466;
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    color: #000;
    padding: 5px 20px
}

#item-details-tab button.active {
    border: 1px solid var(--theme-orange-primary);
    background-color: var(--theme-orange-primary);
    margin-right: 15px;
    /* border-radius: 15px; */
    color: #fff;
}

.dropup .dropdown-toggle::after {
    border: none;
}

.user-profile.icon-sidebar button {
    padding: 0px;
}

.p-datepicker-trigger {
    background-color: transparent;

}

.icon-sidebar a.p-menuitem-link {
    padding: 5px 12px;
    margin-bottom: 10px;
}

span.extend-sidebar {
    position: absolute;
    right: 0px;
    top: 22px;
    background: var(--theme-purple-primary);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    padding-left: 3px;
    padding-top: 2px;
    cursor: pointer;
}

.p-multiselect .p-multiselect-label {
    padding: 0.35rem;
}

.custom-single-dropdown .p-dropdown-label.p-inputtext {
    padding: 0.35rem 0.45rem;
}

.form-group label:first-child {
    margin-bottom: 5px;
}

.file-ext-name {
    font-size: 12px;
    font-weight: 600;
}

.li-hi-36 {
    line-height: 36px;
}

.li-hi-20 {
    line-height: 20px;
}

.txt-dec {
    text-decoration: underline;
    -webkit-text-decoration-color: rgba(0, 0, 0, 0.363);
            text-decoration-color: rgba(0, 0, 0, 0.363);
}

.p-menuitem.p-focus .p-menuitem-content {
    background: transparent !important;
}

.page-title {
    color: var(--heading-color);
    font-size: var(--heading-font-size);
}

.sub-page-title {
    color: var(--sub-heading-color);
    font-size: var(--sub-heading-font-size);
}

body.tooltip-active {
    overflow: hidden !important;
}

.top-2 {
    top: -2px;
}

.lh-40 {
    line-height: 40px;
}

.bg-stepper-line::before {
    content: "";
    width: 75%;
    border: 1px dashed #a9a9a9;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
}

body.is-mobile-screen .bg-stepper-line::before {
    content: "";
    width: 75%;
    border: 1px dashed #a9a9a9;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: -1;
}

.row.stepper {
    position: relative;
    z-index: 5;
}

.row.stepper p {
    background-color: #fff;
    float: left;
    padding: 0px 8px;
    font-size: 16px;
}

.p-dropdown-items {
    margin-bottom: 0px;
}

.fit-height {
    height: calc(100vh - 92px);
}

.login-section-pd {
    padding-left: 110px;
    padding-right: 110px;
}

.line-login::before {
    content: "";
    width: 100%;
    border: 1px solid #f8f9fa;
    display: block;
    position: absolute;
    top: 8px;
    z-index: 1;
}

.line-login span {
    position: absolute;
    z-index: 2;
    background: var(--white-primary);
}

.bg-customer-register {
    background-position: top;
    background-size: 100%;
    background-repeat: no-repeat;
}

.bg-image-one,
.bg-image-two {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-msg-box {
    background-color: var(--theme-orange-third);
}

.dropdown-toggle::after {
    display: none;
}

.customer-profile-img {
    margin-top: 5px;
}

.customer-name-span {
    line-height: 57px;
}

*::placeholder {
    color: #ddd !important;
    opacity: 0.5;
}

.p-placeholder {
    color: #ddd !important;
    opacity: 1;
}

.show-dot::after {
    content: "";
    background: green;
    position: absolute;
    top: 10px;
    left: 38px;
    /* font-size: 34px; */
    width: 5px;
    height: 5px;
    border-radius: 50%;
}

.show-dot-expend::after {
    content: "";
    background: green;
    position: absolute;
    top: 18px;
    left: 90px;
    /* font-size: 34px; */
    width: 5px;
    height: 5px;
    border-radius: 50%;
}

@media only screen and (max-width: 600px) {
    .login-section-pd {
        padding-left: 15px;
        padding-right: 15px;
    }
}

table.no-bg td,
table.no-bg th {
    background-color: #fff;
}

/* .p-multiselect .p-multiselect-label {
    padding: 0.20rem;
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap onto the next line */
/* max-height: none;
} */

.general-heading-logo {
    padding: 1rem !important;
    padding-left: 28px !important;
    padding-right: 28px !important;
}

.profile-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

@media only screen and (max-width: 460px) {
    .profile-align-center {
        text-align: center;
    }

}

.active.show-review-count {
    background: var(--theme-orange-primary);
}

.show-review-count {
    margin-left: auto;
    /* Pushes the badge to the right */
    margin-right: 0;
    /* Optional: ensure there's no extra margin on the right */
    background: var(--theme-purple-primary);
    padding: 2px 6px;
    font-size: 12px;
}

.trade-menu a {
    padding: 5px 2px !important;
}

.min-width-card {
    min-width: 100px;
    /* Set your desired minimum width here */
}

.min-height-card {
    min-height: 145px;
    /* Set your desired minimum width here */
}

.min-height-card1 {
    min-height: 110px;
    /* Set your desired minimum width here */
}

.placeholder-item-image-bg {
    max-width: 100px;
    max-height: 100px;
    border: 1px solid #ddd;
    width: 100%;
    border-radius: 5px;

}

.table-bg-white td,
.table-bg-white th {
    background-color: var(--white-primary);
}

.p-paginator-bottom.p-paginator.p-component {
    padding-left: 0px;
    padding-right: 0px;
}

.over-flow-hide {
    overflow: hidden;
}

.w-30 {
    width: 30px;
    height: 30px;
    object-fit: scale-down;
}


.p-confirm-dialog-reject,
.p-confirm-dialog-reject:active,
.p-confirm-dialog-reject:focus,
.p-confirm-dialog-reject.show {
    border: 1px solid var(--theme-purple-primary);
    outline: none;
    background: transparent;
    color: var(--theme-purple-primary);
    padding-left: var(--button-padding-left);
    padding-right: var(--button-padding-right);
    margin-right: 15px;
    --bs-btn-padding-x: 0.75rem;
    --bs-btn-padding-y: 0.375rem;
    --bs-btn-font-family: ;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: 400;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: var(--bs-border-radius);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    box-shadow: none;
    border-radius: var(--bs-btn-border-radius);
    
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}


.p-confirm-dialog-accept,
.p-confirm-dialog-accept:active,
.p-confirm-dialog-accept.show:active:focus-visible,
.p-confirm-dialog-accept:focus,
.p-confirm-dialog-accept.show {
    border: 1px solid var(--theme-orange-primary);
    outline: none;
    background: var(--theme-orange-primary);
    color: var(--white-primary);
    padding-left: var(--button-padding-left);
    padding-right: var(--button-padding-right);

    --bs-btn-padding-x: 0.75rem;
    --bs-btn-padding-y: 0.375rem;
    --bs-btn-font-family: ;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: 400;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: var(--bs-border-radius);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    box-shadow: none;
    border-radius: var(--bs-btn-border-radius);
    
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}
@media (max-width: 460px) { /* Adjust the max-width as per your breakpoint */
    .sticky-actions {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0; /* Adjust this as needed */
      background-color:var(--white-primary); /* Ensure solid background for sticky area */
      z-index: 10; /* Keep above other elements */
      padding: 10px; /* Optional spacing */
    
    }
  }
  
